import "./statusBadge.css";

export type Status = "Available" 
| "Preparing" 
| "Charging" 
| "SuspendedEVSE" 
| "SuspendedEV" 
| "Finishing" 
| "Unavailable" 
| "Faulted" 
| "Unknown" 
| "Downloading"
| "Offline"
| "RebootAfterReset"
| "ChargeDeniedInvalid"
| "ChargeDeniedMaintenance"
| undefined;

type StatusBadgeProps = {
  status: Status;
  showStandardName?: boolean;
  size?: "sm" | "lg" | "xl";
};

export default function StatusBadge({
  status,
  showStandardName = false,
  size = "lg"
}: StatusBadgeProps) {
  switch (status) {
  case "Available":
    return <span className={`status-badge-available status-badge-${size}`}>Available</span>;
  case "Preparing":
    return <span className={`status-badge-preparing status-badge-${size}`}>{showStandardName ? "Preparing" : "Waiting for Auth"}</span>;
  case "Charging":
    return <span className={`status-badge-charging status-badge-${size}`}>Charging</span>;
  case "SuspendedEVSE":
    return <span className={`status-badge-suspendedEVSE status-badge-${size}`}>Suspended EVSE</span>;
  case "SuspendedEV":
    return <span className={`status-badge-suspendedEV status-badge-${size}`}>Suspended EV</span>;
  case "Finishing":
    return <span className={`status-badge-finishing status-badge-${size}`}>{showStandardName ? "Finishing" : "Unplug Cable"}</span>;
  case "Unavailable":
    return <span className={`status-badge-unavailable status-badge-${size}`}>Unavailable</span>;
  case "Faulted":
    return <span className={`status-badge-faulted status-badge-${size}`}>Faulted</span>;
  case "Downloading":
    return <span className={`status-badge-downloading status-badge-${size}`}>Downloading</span>;
  case "Offline": 
    return <span className={`status-badge-offline status-badge-${size}`}>Offline</span>;
  case "RebootAfterReset":
    return <span className={`status-badge-rebootAfterReset status-badge-${size}`}>Rebooting</span>;
  default:
    return <span className={`status-badge-unknown status-badge-${size}`}>Unknown</span>;
  }
}